body {
  font-family: 'Roboto', sans-serif;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #fcc40f;
}

.MuiFormLabel-root.Mui-focused {
  color: #fcc40f;
}

$theme-colors: (
  'primary': #1bb8ca,
);

.card-header {
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.category-label {
  background-color: #fcc40f;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 13px;
  color: #ffff;
  font-style: italic;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  &.gray {
    background-color: #696969;
  }
  &.blue {
    background-color: #02cdff;
  }
  &.black {
    background-color: #000000;
  }
}

.table-ranking-item_user-info-city {
  .category-label {
    width: 90px;
    height: 20px;
    font-size: 12px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 64em) {
  .hide-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 64em) {
  .hide-mobile {
    display: none !important;
  }
}
