.empty-state {
  width: 100%;
  height: calc(100vh - 350px);
  .empty-state_img {
    width: 30%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 64em) {
  .empty-state {
    height: calc(100vh - 400px);
    .empty-state_img {
      width: 100%;
    }
  }
}
