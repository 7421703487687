.nav {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 15px;

  .nav-row {
    align-items: center;
  }
  .input-auto {
    display: flex;
    justify-content: flex-end;
  }
  .search-container {
    position: relative;
    width: 300px;
    display: flex;
    .search-icon {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 14px;
      left: 17px;
    }
    input {
      outline: none;
      width: 100%;
      padding: 10px 10px 10px 40px;
      border-radius: 25px;
      border: solid 1px #000000;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.14px;
      color: #6d7278;
    }
  }

  .segment-map {
    height: 65px;
    margin-right: 16px;
  }

  .segment-select-row {
    align-items: center;
  }
  .select-auto {
    justify-content: left;
    display: flex;
  }
  .segment-select-container {
    .MuiSelect-root {
      padding: 0;
      padding-right: 25px;
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.23px;
      color: #000000;
      text-align: left;
      &:hover {
        border: none;
      }
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border: none;
    }

    .MuiSelect-select:focus {
      background: transparent;
    }

    .MuiSvgIcon-root {
      color: #fcc40f;
    }
  }

  .nav_title {
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.21px;
    line-height: 24px;
    width: 100%;
    text-align: left;
  }

  .nav_filter {
    margin-left: 30px;
    cursor: pointer;

    height: 40px;
    // width: 24px;
    &:hover {
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: 64em) {
  .nav {
    padding: 0;

    .search-container {
      width: 100%;
    }

    .select-limit {
      width: 100%;
    }
    .select-mobile-container {
      display: flex;
      align-items: center;
    }
    .input-auto {
      margin-bottom: 15px;
    }

    .segment-select-container {
      width: 100%;
    }
    .nav_filter {
      margin-left: 10px;
    }
  }
}
