.registration-page {
  .registration-page-container {
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: calc(100vh - 112px);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .entry-types {
    padding: 15px;
    margin-bottom: 20px;

    .entry-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      margin-bottom: 10px;
      cursor: pointer;
      background-color: #ffffff;
      transition: background-color 0.3s;

      &.selected {
        background-color: #fcc30f;
        color: #000;
        font-weight: bold;
      }

      &:hover {
        background-color: #ffe59e;
      }

      h6 {
        margin: 0;
        font-size: 16px;
      }

      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }

  .registration-form {
    padding: 15px;
    margin-bottom: 20px;

    .MuiTextField-root {
      margin-bottom: 15px;
    }

    .MuiFormControl-root {
      margin-bottom: 15px;
    }
  }

  .summary {
    margin-left: 0;
    border: 1px solid #ccc;
    padding: 15px;

    .MuiTextField-root {
      margin-bottom: 15px;
    }

    .MuiButton-root {
      margin-top: 15px;
      background-color: #fcc30f;
      color: #000;
      font-weight: bold;

      &:hover {
        background-color: #e5ac0e;
      }
    }
  }

  .form-title {
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px !important;
  }

  .field-container {
    margin-bottom: 10px;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fcc40f;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fcc40f;
  }

  .MuiTypography-root {
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    position: relative; /* Asegura que los errores se posicionen correctamente */
    margin-bottom: 15px;

    .MuiTypography-colorError {
      margin-top: 4px; /* Espacio entre el Select y el error */
      font-size: 12px; /* Tamaño similar al helperText de los TextField */
      color: #f44336; /* Color de error */
      text-align: left; /* Alinea a la izquierda */
    }
  }

  @media (min-width: 768px) {
    .summary {
      margin-left: 20px;
      position: sticky;
      top: 20px;
    }
  }
}
