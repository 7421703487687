.table-ranking {
  .table-ranking_title-container {
    height: 36px;
    background-color: #fcc40f;
    align-items: center;
  }

  .table-ranking_title {
    padding: 0;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 16px;
    border-right: 1px solid #000000;
    &:last-child {
      border: none;
    }
    &.name {
      text-align: left;
      padding-left: 52px;
    }
  }
}

@media only screen and (max-width: 64em) {
  .table-ranking {
    .table-ranking_title {
      &.name {
        text-align: left;
        padding-left: 43px;
      }
    }
  }
}
