.collect-kit-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .collect-kit-modal_card {
    position: relative;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 90%;
    height: 450px;
    border-radius: 0;
    background-color: #ffffff;
    overflow-y: auto;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .yellow-container {
    height: 230px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .calendar-icon {
    width: 65px;
    margin-bottom: 20px;
    margin-top: 65px;
  }
  .close-icon {
    height: 14px;
    width: 14px;
    color: #252525;
  }

  .yellow-container {
    background: #fcc30f;
  }

  .login-welcome {
    position: relative;
    width: 90%;
    height: 222px;
    margin: 20px 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    flex-direction: column;
  }

  .black-container {
    height: 48px;
    width: 100%;
    position: absolute;
    top: 0;
    border: solid 2px #000000;
    border-bottom: none;
  }

  .white-container {
    height: 170px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border: solid 2px #ffffff;
    border-top: none;
  }

  .welcome-description {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    &.white {
      color: #ffffff;
    }
  }

  .welcome-title {
    position: absolute;
    z-index: 1;
    top: -20px;
    padding: 11px 30px;
    border-radius: 8px;
    background-color: #000000;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.21px;
    color: #ffffff;
  }

  .collect-kit-modal_card-body {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    .filter-button {
      margin-top: 25px;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 16px;
      outline: none;
      cursor: pointer;
      background: #fcc30f;
      padding: 0;
      border-radius: 25px;
      border: none;
      &:hover {
        opacity: 0.7;
      }

      &:disabled {
        background: transparent;
        border: solid 2px #000000;
        cursor: not-allowed;
      }
    }
  }

  .collect-kit-modal_card-section {
    margin-bottom: 20px;
    .collect-kit-modal_card-section-title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 16px;
      text-align: start;
      margin-bottom: 10px;
    }
    .tags-container {
      display: flex;
      .tag {
        width: 100%;
        margin-right: 5px;
        padding: 5px 10px;
        border-radius: 14px;
        border: 0.5px solid #979797;
        opacity: 0.5;
        color: #000000;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.12px;
        line-height: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        &.selected {
          background-color: #ffc500;
          opacity: 1;
          border: none;
          font-weight: bold;
        }
      }

      .sport-container {
        border: 0.5px solid #979797;
        border-radius: 6px;
        background-color: #ffffff;
        padding: 5px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .category-img {
          height: 25px;
        }

        .triathlon-img {
          opacity: 0.3;
          width: 90px;
        }
        &.selected {
          background: #ffc500;
          .bike-img,
          .triathlon-img {
            opacity: 1;
          }
          border: 0.5px solid #000000;
        }
      }
    }
  }
}

@media only screen and (min-width: 64em) {
  .collect-kit-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .collect-kit-modal_card {
      position: relative;
      width: 400px;
      border-radius: 0;
    }
  }
}
