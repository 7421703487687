.fixed-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding-top: 10px;
}

.table-ranking-item {
  .table-ranking-item_column-container {
    align-items: center;
  }

  .add-button {
    // border: solid 1px #000000;
    // border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 15px;
    }
  }

  .position-text {
    margin-left: 4px;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: 0.26px;
  }

  .table-ranking-item_column {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .table-ranking-item_user-img-container {
    border-radius: 50%;
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .table-ranking-item_user-img {
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .table-ranking-item_user-info-container {
    width: 100%;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .specialized-icon {
    width: 70px;
  }

  .table-ranking-item_user-info-name {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;
  }

  .table-ranking-item_user-info-team {
    display: flex;
    justify-content: start;
    span {
      color: #777777;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.11px;
      line-height: 14px;
      // background: #fcc30f;
      padding: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .table-ranking-item_user-info-city {
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.11px;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .category-img-item {
    height: 20px;
  }

  .table-ranking-item_category {
    margin-top: 5px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: #fcc30f;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    &.mens {
      padding: 0 3px;
      border-radius: 5px;
      color: #f4f4f4;
      background: #54baee;
    }
    &.women {
      padding: 0 3px;
      border-radius: 5px;
      color: #f4f4f4;
      background: #ff2a8a;
    }
    &.mixed {
      padding: 0 3px;
      border-radius: 5px;
      color: #f4f4f4;
      background: #95d42c;
    }
    .table-ranking-item_user-info-team {
      margin-top: 3px;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.09px;
      color: #777777;
      padding: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #777777;
        margin-right: 3px;
      }
    }
  }

  .down-icon {
    height: 10px;
    margin-left: 5px;
  }

  .table-ranking-item_column-container_main {
    height: 71px;
    border-bottom: 0.5px solid #979797;
    &.individual {
      .table-ranking-item_user-img-container {
        border-radius: 50%;
        height: 40px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &.specialized {
          width: 200px;
          display: flex;
          justify-content: flex-start;
        }
      }

      .table-ranking-item_user-img {
        position: absolute;
        overflow: hidden;
        object-fit: cover;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }

  .table-ranking-item_column-container_secundary {
    padding-right: 8px;
    background: #f4f4f4;
    height: 71px;
    border-bottom: 0.5px solid #979797;
    &.individual {
      .table-ranking-item_user-img-container {
        border-radius: 50%;
        height: 40px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      .table-ranking-item_user-img {
        position: absolute;
        overflow: hidden;
        object-fit: cover;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }

  .triathlon_logo {
    width: 68px;
  }

  .bike_logo {
    width: 22px;
  }

  .strava-logo {
    bottom: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    border-radius: 50%;
    &.individual {
      margin-left: 5px;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &.darkMode {
    background: #333333;
    color: #f4f4f4;
    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .table-ranking-item_column-container_secundary {
      color: #000000;
    }
    .table-ranking-item_user-info-team {
      color: #f4f4f4;
      span {
        color: #f4f4f4;
      }
    }
  }
}

.home {
  padding-bottom: 61px;

  .sub-header {
    padding: 0 40px;
  }

  .banner {
    width: 100%;
  }

  .last-update {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    background-color: #ec1d23;
    color: #f4f4f4;
    font-size: 15px;
    font-weight: 600;
  }

  .wp-fixed {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 50px;
    z-index: 9999;

    button {
      cursor: pointer;
      border: none;
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      border-radius: 50px;
      background-color: #f02832;
      color: #f4f4f4;
      font-size: 15px;
      font-weight: 400;
      img {
        width: 30px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media only screen and (max-width: 64em) {
  .home {
    .sub-header {
      padding: 0 8px;
    }
  }

  .table-ranking-item
    .table-ranking-item_category
    .table-ranking-item_user-info-team
    span {
    margin-right: 1px;
  }

  .table-ranking-item .table-ranking-item_category {
    font-size: 12px;
  }
  .table-ranking-item
    .table-ranking-item_category
    .table-ranking-item_user-info-team {
    font-size: 8px;
  }

  .table-ranking-item .table-ranking-item_user-info-team span {
    font-size: 10px;
  }

  .table-ranking-item {
    .specialized-icon {
      width: 40px;
    }
    .position-text {
      font-size: 20px;
    }
    .table-ranking-item_user-info-city {
      .category-img-item {
        width: 90%;
      }
    }
    .table-ranking-item_user-info-name {
      font-size: 10px;
    }
    .table-ranking-item_column-container_main {
      &.individual {
        .table-ranking-item_user-img-container {
          &.specialized {
            width: 50px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
