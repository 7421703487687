.footer {
  padding: 10px 0;
  background-color: #000000;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #e4e7e7;
  &.home {
    position: fixed;
  }
  .footer-logo {
    margin-left: 5px;
    height: 15px;
  }
}
