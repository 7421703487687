.filter-modal {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);

  .category-label {
    height: 25px;
    width: 114px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #fcc30f;
  }

  .filter-modal_card {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 400px;
    border-radius: 20px 20px 0 0;
    background-color: #ffffff;
    overflow-y: auto;
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    }
  }

  .filter-modal_card-header {
    position: sticky;
    top: 0;
    height: 37px;
    display: flex;
    align-items: flex-start;
    padding: 19px;
    padding-right: 8px;
    border-bottom: 0.5px solid #979797;
    background: #ffffff;
    z-index: 1;
    .filter-modal_card-header_filter {
      height: 25px;
      width: 24px;
      margin-right: 11px;
    }
    .filter-modal_card-header_title {
      color: #000000;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.21px;
      line-height: 24px;
      text-align: left;
      width: 100%;
    }
    .filter-modal_card-header-left {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .close-button {
      padding: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .close-icon {
      height: 14px;
      width: 14px;
      color: #252525;
    }
  }

  .filter-modal_card-body {
    padding: 14px 31px;
    .filter-button {
      margin-top: 25px;
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 16px;
      outline: none;
      cursor: pointer;
      background: #fcc30f;
      padding: 0;
      border-radius: 25px;
      border: none;
      &:hover {
        opacity: 0.7;
      }

      &:disabled {
        background: transparent;
        border: solid 2px #000000;
        cursor: not-allowed;
      }
    }
  }

  .filter-modal_card-section {
    margin-bottom: 20px;
    .filter-modal_card-section-title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 16px;
      text-align: start;
      margin-bottom: 10px;
    }
    .tags-container {
      display: flex;
      .tag {
        width: 100%;
        margin-right: 5px;
        padding: 5px 10px;
        border-radius: 14px;
        border: 0.5px solid #979797;
        opacity: 0.5;
        color: #000000;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.12px;
        line-height: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        &.selected {
          background-color: #ffc500;
          opacity: 1;
          border: none;
          font-weight: bold;
        }
      }

      .sport-container {
        border: 0.5px solid #979797;
        border-radius: 6px;
        background-color: #ffffff;
        padding: 5px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .category-img {
          height: 25px;
        }
        .triathlon-img {
          opacity: 0.3;
          width: 90px;
        }
        &.selected {
          background: #ffc500;
          .bike-img,
          .triathlon-img {
            opacity: 1;
          }
          border: 0.5px solid #000000;
        }
      }
    }
  }
}

@media only screen and (min-width: 64em) {
  .filter-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .filter-modal_card {
      position: relative;
      width: 400px;
      border-radius: 0;
    }
  }
}
