.login {
  flex-direction: column;
  display: flex;
  align-items: center;
  background: url('../../assets/images/COL-CH-1080X1920.png') no-repeat center
    center fixed; // mobile

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  justify-content: center;

  .login_logo {
    top: 50px;
    position: absolute;
    height: 134px;
    width: 97px;
  }

  .bike_log {
    height: 107px;
    margin-bottom: 38px;
  }

  .three-arrow {
    height: 25px;
    margin-bottom: 30px;
  }

  .id_logo {
    margin-bottom: 46px;
    width: 123px;
  }

  .sign-up-description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 33px;
  }

  .field-container {
    width: 100%;
    span {
      text-align: justify;
    }
  }

  .field-margin {
    margin-bottom: 30px !important;
  }

  .link-term {
    text-decoration: underline;
    cursor: pointer;
    color: #54baee;
  }

  .login_button {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.11px;
    line-height: 16px;
    border-right: 1px solid #000;
    background: #ff4b03;
    border-radius: 25px;
    height: 45px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 45px;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    img {
      margin-left: 5px;
      width: 61px;
    }
    margin-bottom: 16px;
  }

  .sign-up-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.21px;
    color: #000000;
    margin-bottom: 100px;
  }

  .strava-bnt {
    height: 45px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .submit-button {
    bottom: 50px;
    margin-bottom: 16px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 16px;
    outline: none;
    cursor: pointer;
    background: #fcc30f;
    padding: 0;
    border-radius: 25px;
    border: none;
    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      background: transparent;
      border: solid 2px #000000;
      cursor: not-allowed;
    }
  }

  .guess-button {
    background: transparent;
    border: 2px solid #fcc30f;
    color: #000000;
  }

  .filter-button {
    background: transparent;
    border: 2px solid #000;
  }

  .login-info {
    // position: absolute;
    // bottom: 0;
    margin-top: 100px;
    width: 100%;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login-inputs {
    background: white;
    padding-top: 50px;
    border-top: solid;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fcc40f;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fcc40f;
  }

  .MuiSelect-root {
    text-align: left;
  }

  .field-container {
    margin-bottom: 90px;
  }
}

// @media only screen and (min-width: 64em) {
//   .login {
//     background: url('../../assets/images/loginweb.jpg');
//   }
// }

@media only screen and (min-width: 64em) {
  .login {
    background: url('../../assets/images/COL-CH-1920X1080.png') no-repeat center
      center fixed; // web
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    .submit-button {
      width: 256px;
    }
    .field-container {
      width: 535px;
    }

    .login-info {
      // position: absolute;
      // bottom: 0;
      margin-top: 200px;
      width: 100%;
    }

    .login-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
