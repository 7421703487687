.header {
  height: 67px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;

  .reset-filters {
    color: #fbc310;
    font-size: 12px;
    letter-spacing: 0.14px;
    line-height: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    z-index: 1;
  }

  .header_profile-photo-container {
    z-index: 2;
    border-radius: 50%;
    border: solid 2px #fbc310;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fbc310;
    height: 44px;
    width: 44px;
  }
  .name-container {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 10px;
  }

  .edit-info-container {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .hover {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .header-size {
    height: 40px;
    width: 40px;
    z-index: 1;
    border-radius: 50%;
  }

  .header_profile-photo {
    overflow: hidden;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .edit_icon {
    position: absolute;
    width: 20px;
    bottom: 0;
    right: -7px;
  }
  .header_container_logo {
    width: 100%;
    position: absolute;
    left: 0;
  }
  .header_logo {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    width: 140px;
  }
  .add-logo {
    height: 40px;
  }
  .header_strava_logo {
    height: 31px;
    width: 30px;
  }
  .add-button-container {
    position: relative;
  }
  .add-button {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #fcc30f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1px;
    right: -5px;
  }
}
