/* Webfont: Roboto-Black */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-BlackItalic */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-Bold */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-BoldItalic */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-Italic */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-Light */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-LightItalic */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-Medium */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-MediumItalic */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Roboto-Regular */
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
