.photos {
  padding-bottom: 61px;

  .sub-header,
  .body {
    padding: 0 40px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 35px;
    letter-spacing: 0.3px;
    text-align: left;
    span {
      background: #ffc500;
      padding-right: 5px;
    }
  }

  .separattor {
    width: 79px;
    height: 8px;
    background-color: #000000;
    margin: 26px 0;
  }

  .sub-title {
    text-align: left;
    opacity: 0.65;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #000000;
  }

  .contact {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    a {
      &:hover {
        opacity: 0.7;
      }
      height: 42px;
      margin-right: 17px;
      img {
        height: 100%;
      }
    }
  }

  .carousel {
    .thumbs-wrapper {
      margin-top: 5px;
      height: 153px;
      .thumbs {
        height: 100%;
        .thumb {
          border: none;

          &.selected {
            border: 4px solid #ffc500;
          }
          padding: 0;
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .carousel-margin {
    padding-left: 60px;
    position: relative;
    align-items: center;

    .floating-arrows {
      position: absolute;
      left: 0;
      z-index: 1;
      margin-bottom: 130px;
    }
    .arrows-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      .arrow-separator {
        position: absolute;
        width: 3px;
        height: 56px;
        background-color: #ffffff;
        z-index: 2;
      }
      .left-arrow {
        background-color: #fcc40f;
      }
      .rigth-arrow {
        background-color: #000000;
      }
      .arrow-item {
        height: 72px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        outline: none;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
      img {
        height: 29px;
      }
    }
  }

  .carousel-container {
    width: 100%;
    .download-icon {
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.14px;
      color: #000000;

      padding: 7px 8px 7px 11px;
      border-radius: 9px;
      background-color: #ffc500;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      position: absolute;
      top: 18px;
      right: 28px;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }

      img {
        height: 18px;
        margin-right: 5px;
      }
    }
    .carousel-size {
      width: 100%;
    }
    position: relative;
    display: flex;
    align-items: center;

    .floating-arrow {
      position: absolute;
      left: 8px;
      z-index: 1;
      margin-bottom: 85px;
    }
    .floating-arrow-right {
      position: absolute;
      right: 8px;
      z-index: 1;
      margin-bottom: 85px;
    }
    .arrow-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .arrow-item {
        background-color: #fcc40f;
        width: 30px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        outline: none;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
      img {
        height: 14px;
      }
    }
  }
  .carousel .slide img {
    height: 500px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 64em) {
  .photos {
    .title {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.17px;
    }

    .body {
      padding: 0 15px;
    }

    .separattor {
      width: 79px;
      height: 8px;
      background-color: #000000;
      margin: 14px 0 26px 0;
    }

    .sub-title {
      font-size: 14px;
      line-height: 1.07;
      letter-spacing: normal;
    }

    .contact {
      margin-top: 16px;
      a {
        height: 26px;
        margin-right: 10px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .carousel-margin {
      padding-left: 0;
      margin-right: -15px;
      margin-left: -15px;
    }
    .sub-header {
      padding: 0 8px;
    }
    .carousel-container {
      padding: 0;
    }

    .carousel-container {
      .download-icon {
        top: 10px;
        right: 10px;
        font-size: 12px;
        img {
          height: 15px;
        }
      }
    }

    .carousel {
      .slide img {
        height: 100%;
        object-fit: contain;
      }
      .thumbs-wrapper {
        margin-top: 5px;
        height: 70px;
      }
    }
  }
}
