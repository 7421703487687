.tabs {
  margin-bottom: 15px;
  margin-top: 0;
  display: flex;
  .tab {
    padding: 10px;
    width: 100%;
    // opacity: 0.5;
    cursor: pointer;

    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.26px;
    color: #000000;

    border-bottom: solid 1px #000000;
    &.selected {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: 0.26px;
      color: #000000;
      opacity: 1;
      border-bottom: solid 3px #fcc40f;
    }
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      color: #777777;
      border-color: #777777;
      cursor: not-allowed;
      opacity: 1;
    }
  }
}
