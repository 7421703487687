.edit-athlete {
  .three-arrow {
    height: 25px;
    margin-bottom: 60px;
  }

  .edit-title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.21px;
    text-align: left;
    color: #000000;
    margin-bottom: 18px;
  }

  .edit-title-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fcc40f;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fcc40f;
  }
  .photo-section {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    .photo-background {
      background: #fcc30f;
      width: 100%;
      height: 126px;
    }

    .border-white-left {
      position: absolute;
      top: 120px;
      left: -100px;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
    }

    .border-white-right {
      position: absolute;
      top: 120px;
      right: -101px;
      border-radius: 50%;
      height: 200px;
      width: 200px;
      background-color: #fff;
    }

    .photo-container {
      position: absolute;
      top: 35px;
      .photo-area {
        border: solid 15px #fcc30f;
        height: 145px;
        width: 145px;
        background-color: #000000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .upload-photo {
        height: 40px;
        width: 50px;
      }
      .user-photo {
        position: absolute;
        object-fit: cover;
        height: 145px;
        width: 145px;
        border-radius: 50%;
      }
    }
  }

  .field-container {
    margin-bottom: 20px;
  }

  .filter-modal_card-section {
    margin-top: 20px;
    .filter-modal_card-section-title {
      color: #fcc30f;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 16px;
      text-align: start;
      margin-bottom: 10px;
    }
    .tags-container {
      display: flex;
      .sport-container {
        border: 0.5px solid #979797;
        border-radius: 6px;
        background-color: #ffffff;
        padding: 5px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .bike-img {
          opacity: 0.3;
          width: 45px;
        }
        .triathlon-img {
          opacity: 0.3;
          width: 90px;
        }
        &.selected {
          background: #ffc500;
          .bike-img,
          .triathlon-img {
            opacity: 1;
          }
          border: 0.5px solid #000000;
        }
      }
    }
  }

  .submit-button {
    margin-top: 57px;
    margin-bottom: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 16px;
    outline: none;
    cursor: pointer;
    background: #fcc30f;
    padding: 0;
    border-radius: 25px;
    border: none;
    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      background: transparent;
      border: solid 2px #000000;
      cursor: not-allowed;
    }
  }

  .filter-button {
    background: transparent;
    border: 2px solid #000;
  }

  .log-out {
    margin-top: 10px;
    background: transparent;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 25px;
  }

  .MuiSelect-root {
    text-align: left;
  }
}

@media only screen and (min-width: 64em) {
  .edit-athlete {
    .photo-section {
      height: 220px;
      margin-bottom: 20px;
      .photo-background {
        background: transparent;
      }
      .photo-container {
        border: solid 12px #fcc30f;
        border-radius: 50%;
        .photo-area {
          border: solid 8px #000000;
        }
      }
    }

    .edit-title {
      text-align: center;
    }

    .edit-title-container {
      align-items: center;
    }
  }
}
