.payment-success-page {
  .container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-container,
  .error-container {
    text-align: center;
    margin-top: 20px;
  }

  .transaction-summary {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    .title {
      text-align: center;
      margin-bottom: 20px;
    }

    .summary-section {
      margin-bottom: 20px;

      .details-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        p {
          margin: 0;
        }
      }

      .status {
        font-weight: bold;
      }

      .APPROVED {
        color: #4caf50;
      }
      .PENDING {
        color: #ff69b4;
      }
    }
  }

  button {
    display: block;
    margin: 0 auto;
    background-color: #fcc30f;
    color: #000;
    font-weight: bold;

    &:hover {
      background-color: #e5ac0e;
    }
  }
}
